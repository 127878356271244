import React from "react"
import HTML from "../components/HTML"
import Layout from '../components/layout'
import SEO  from '../components/SEO'

function rawHTML(props) {
  const { pageContext } = props
  const { pageContent, theme, dealers, dealerInfo } = pageContext

  return (
    <Layout>
      <HTML key={"HTML"} data={pageContent.HTML} theme={theme} dealer={dealerInfo} />
      <SEO key={"SEO"} title={"Contact"} data={pageContent.SEO} />
    </Layout>
  )
}
export default rawHTML